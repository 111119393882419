
import React from "react";
import userimg from "../../../../../public/images/userimg.svg";
import { getTimeDiffOutput } from "../../../../../utilities/helper";

function isWithinOneMinute(date1, date2) {
  return Math.abs(new Date(date1) - new Date(date2)) < 60000;
}

export default function LeftBlock(props) {
  const { messageObj, index, key, messages, currentUser } = props;
  const current_id = currentUser?.user_id;

  const userImage = messageObj?.userDetail?.userInfo?.profilePhoto || userimg;

  const leftMessages = messages.filter(
    (message) => message?.byUser !== current_id
  );

  const leftIndex = leftMessages.findIndex((msg) => msg?._id === messageObj?._id);
  const isFirstMessage = leftIndex === 0;
  const isLastMessage = leftIndex === leftMessages.length - 1;
  const previousMessage = !isFirstMessage ? leftMessages[leftIndex - 1] : null;

  const nextMessage = !isLastMessage ? leftMessages[leftIndex + 1] : null;

  const isStartOfInterval =
    isFirstMessage ||
    !isWithinOneMinute(previousMessage?.createdAt, messageObj?.createdAt);
  const isEndOfInterval =
    isLastMessage ||
    !isWithinOneMinute(messageObj?.createdAt, nextMessage?.createdAt);

  return (
    <li className="sender d-flex" key={key}>
      <div>
        {isStartOfInterval && (
          <img className="msg-userImg" src={userimg} alt="" />
        )}
        {messageObj?.image ? <img className="comming-image" src={messageObj?.image} width={100} height={100} style={{ marginRight: isStartOfInterval ? "20px" : "55px" }} /> : ""}
        {messageObj?.message && messageObj?.message !== "" && <p
          className={`marginleft ${isStartOfInterval ? "" : "intervalGapleft"}`}
        >
          {messageObj?.message}
        </p>}
        {isEndOfInterval && (
          <span className={`time ${isStartOfInterval ? "intervalGapleft" : "intervalGapleft"}`}>
            {getTimeDiffOutput(messageObj?.createdAt)}
          </span>
        )}
      </div>
    </li >
  );
}
