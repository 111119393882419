import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteAccount, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import { toast } from "react-toastify";
import * as images from "../../utilities/images";
import { userRoleName, userRoles } from "../../utilities/helper";

export default function DeleteAccount() {
  const dispatch = useDispatch();
  const authData = useAuthSelector();
  const [errorMessages, setErrorMessages] = useState({});
  const [role, setRole] = useState(null)
  const [deleted, setDeleted] = useState(false)
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // Getting values of input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    if (!formData.email) {
      formIsValid = false;
      errors = { ...errors, email: "Please enter your email." };
    } else if (typeof formData.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formData.email.trim())) {
        formIsValid = false;
        errors = { ...errors, email: "Please enter valid email." };
      }
    }

    if (!formData?.password) {
      formIsValid = false;
      errors = { ...errors, password: "Please enter your password." };
    }
    setErrorMessages(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!role && role == null) {
      toast.error("Please select role again");
    } else if (!formData?.email) {
      toast.error("Please enter email");
      return;
    } else if (
      formData?.email &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        formData.email
      )
    ) {
      toast.error("Please enter valid email address");
      return;
    } else if (!formData?.password) {
      toast.error("Please enter password");
      return;
    } else if (formData?.password.length <= 7) {
      toast.error("Password should be maximum 8 character");
      return;
    }
    if (validateForm()) {
      let params = {
        email: formData.email.trim(),
        password: formData.password,
        role
      };
      dispatch(
        deleteAccount({
          ...params,
          cb(ress) {
            if (ress.status === 200) {
              setDeleted(true)
              setRole(null)
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      setRole(null)
      setDeleted(false)
    }
  }, []);
  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);



  return (
    <div className="login-page authBg adminlgn">
      <div className="card card-outline card-primary cardTopOutline cardBg">
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={images.OrigoLogo}
            className="brand_logo"
            alt="Brand Logo"
            style={{ width: "200px" }}
          />
        </div>
        {deleted ? <div>
          <h5 className="mt-5">
            Thank you,
          </h5>
          <p className="">
            Your account has been deleted successfully. you can go to <a href="http://myhomeamigos.com/">Website</a>
          </p>
        </div> : <div>
          <div className="card-header text-center">
            <h3 className="mb-0 logintext">{!role ? "Choose Role" : `Delete account for ${userRoleName[role]}`}</h3>
          </div>
          <div>
            {!role ?
              <div className="role_select">
                <button
                  className="loginBtnCommon loginbtn"
                  onClick={() => setRole(userRoles.client)}
                >
                  User
                </button>
                <button
                  className="loginBtnCommon loginbtn"
                  onClick={() => setRole(userRoles.provider)}
                >
                  Provider
                </button>
              </div>
              : <div className="card-body login-card-body mt-4">
                <img src={images.BackIcon} className="backButton" onClick={() => setRole(null)} />
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="input-container  ">
                    <input
                      type="text"
                      className="form-control form-input borderinput"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label for="username" className="form-label d-block inputBox">
                      Email{" "}
                    </label>
                    <span className="error invalid-feedback">
                      {errorMessages.email}
                    </span>
                  </div>
                  <div className="input-container mt-4 mb-2">
                    <input
                      type="password"
                      className="form-control form-input borderinput"
                      name="password"
                      placeholder="Password"
                      value={formData.password}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label for="username" className="form-label d-block inputBox">
                      Password
                    </label>
                    <span className="error invalid-feedback">
                      {errorMessages.password}
                    </span>
                  </div>

                  <div className="row">
                    <div className="col-12 text-end">
                      <Link
                        to="/forgot-password"
                        className="
                         forgotheading"
                      >
                        Forgot password?
                      </Link>
                    </div>

                    <div className="col-12 text-center">
                      <button
                        className="loginBtnCommon mt-5 mw-100 loginbtn"
                        type="submit"
                      >
                        {authData.loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Delete
                      </button>
                    </div>
                  </div>
                </form>
              </div>}
          </div>
        </div>}
      </div>
    </div>
  )
}
