import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  loading: false,
  loggedInUser: {},
  chefId: {},
  adminEmail: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetPassword: (state) => {
      state.loading = true;
    },
    setResetPassword: (state, action) => {
      state.loading = false;
    },
    resendEnterOtp: (state) => {
      state.loading = true;
    },
    setrResendEnterOtp: (state, action) => {
      state.loading = false;
    },
    enterOtp: (state) => {
      state.loading = true;
    },
    setEnterOtp: (state, action) => {
      state.loading = false;
      state.chefId = action.payload;
    },
    forgotPassword: (state) => {
      state.loading = true;
    },
    setForgotPassword: (state, action) => {
      state.loading = false;
      state.adminEmail = action.payload;
    },
    adminLogin: (state) => {
      state.loading = true;
    },
    setAdminLogin: (state, action) => {
      state.loading = false;
    },
    deleteAccount: (state) => {
      state.loading = true;
    },
    setDeletedAccount: (state, action) => {
      state.loading = false;
    },
    adminLogout: (state) => {
      state.loading = true;
    },
    setAdminLogout: (state, action) => {
      state.loading = false;
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  forgotPassword,
  setForgotPassword,
  adminLogin,
  setAdminLogin,
  deleteAccount,
  setDeletedAccount,
  onErrorStopLoad,
  adminLogout,
  setAdminLogout,
  enterOtp,
  setEnterOtp,
  resendEnterOtp,
  setrResendEnterOtp,
  resetPassword,
  setResetPassword,
  setLoggedInUser,
} = authSlice.actions;

export default authSlice.reducer;
