import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isFetching: false,
  conversations: {},
  fetchedConversation: [],
  messages: {},
  fetchedMessages: [],
  conversationsById: {},
  activeConversation: null,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetConversation: (state, action) => {
      state.activeConversation = null;
      state.messages = {};
      state.fetchedMessages = [];
      state.conversations = {};
      state.conversationsById = {};
      state.fetchedConversation = [];
    },
    getAllConversation: (state, action) => {
      state.loading = true;
    },
    setAllConversations: (state, action) => {
      state.loading = false;
      state.conversations = action.payload?.data?.data || {};
      state.fetchedConversation = action?.payload?.data?.data?.conversations || [];
    },
    getMoreConversation: (state, action) => {
      state.loading = true;
    },
    setMoreConversations: (state, action) => {
      state.loading = false;
      state.conversations = action.payload.data.data || {};
      state.fetchedConversation = [...state.fetchedConversation, ...action.payload.data.data.conversations] || [];
    },
    updateConversationList: (state, action) => {
      state.loading = false;
      state.conversations = { ...state.conversations, conversations: action.payload } || {};
      state.fetchedConversation = action.payload || [];
    },
    getConversationById: (state, action) => {
      state.loading = true;
      state.activeConversation = null;
    },
    setConversationById: (state, action) => {
      state.loading = false;
      state.conversationsById = action?.payload?.data?.data || {};
      state.activeConversation = action?.payload?.data?.data?._id || null;
    },
    getAllMessages: (state, action) => {
      state.loading = true;
    },
    getOneAllMessages: (state, action) => {
      state.loading = true;
    },
   
    setOneAllMessage: (state, action) => {
      state.loading = false;
      state.messages = action.payload.data.data || {};
      state.fetchedMessages = action.payload.data.data.messages || [];
    },
    setAllMessages: (state, action) => {
      state.loading = false;
      state.messages = action.payload.data.data || {};
      state.fetchedMessages =
        [...action.payload.data.data.messages, ...state.fetchedMessages] || [];
    },
    setActiveConversaion: (state, action) => {
      state.activeConversation = action.payload;
    },
    sendMessage: (state, action) => { },
    setNewMessage: (state, action) => {
      let isExist = state.fetchedMessages.find(
        (item) => item?._id == action.payload?._id
      );
      if (!isExist)
        state.fetchedMessages = [...state.fetchedMessages, action.payload];
    },
    setUpdatingCount: (state, action) => {
      state.conversations =
        {
          ...state.conversations,
          conversations: action.payload || [],
        } || {};
      state.fetchedConversation = action.payload || [];
    },
    clearMessage: (state, action) => {
      state.loading = false;
      state.messages = {};
      state.fetchedMessages = [];
    },
    chatClearMessage: (state) => {
      state.loading = true;
    },
    setChatClearMessage: (state) => {
      state.loading = false;
    },
    markRead: (state) => { },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

export const {
  markRead,
  sendMessage,
  clearMessage,
  setNewMessage,
  getAllMessages,
  getOneAllMessages,
  setAllMessages,
  setOneAllMessage,
  onErrorStopLoad,
  getAllConversation,
  getMoreConversation,
  setUpdatingCount,
  setAllConversations,
  setMoreConversations,
  resetConversation,
  getConversationById,
  setConversationById,
  setActiveConversaion,
  updateConversationList,
  chatClearMessage,
  setChatClearMessage,
} = chatSlice.actions;

export default chatSlice.reducer;
