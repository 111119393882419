import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getConversationById,
  getAllConversation,
  updateConversationList,
  getMoreConversation,
} from "../../../../redux/slices/chat";
import { useChatSelector } from "../../../../redux/selector/chat";
import ListBlock from "./ListBLock";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";

export default function ChatList(props) {
  const dispatch = useDispatch();
  const chatSelector = useChatSelector();
  let fetchedConversations = chatSelector.fetchedConversation;
  const [page, setPage] = useState(1);
  const [pageLimit] = useState(10)
  const [pageCount, setPageCount] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const [hasMore, setHasMore] = useState(true);

  const getConversations = () => {
    let params = {
      page: page,
      limit: pageLimit,
    };

    if (searchInput && searchInput != "")
      params.search = searchInput

    dispatch(
      getAllConversation({
        ...params,
        cb(res) {
          setPageCount(res?.data?.data?.total_pages);
          if (page > res?.data?.data?.total_page) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        },
      })
    );
  };

  const getPaginatedConversation = (page) => {
    let params = {
      page: page,
      limit: pageLimit,
    };
    if (searchInput && searchInput != "")
      params.search = searchInput

    dispatch(
      getMoreConversation({
        ...params,
        cb(res) {
          // setPageCount(res?.data?.data?.total_pages);
          if (page > res?.data?.data?.total_page) {
            setHasMore(false);
          }
        },
      })
    );
  };

  useEffect(() => {
    getConversations();
  }, [searchInput]);

  const fetchMoreData = () => {
    if (page >= pageCount) {
      setHasMore(false);
      return;
    }

    if (page < pageCount) {
      getPaginatedConversation(page + 1);
      setPage(page + 1);
    }
  };

  const activeConversation = (conversationId) => {
    if (
      chatSelector.activeConversation &&
      chatSelector.activeConversation == conversationId
    )
      return;
    let params = {
      conversationId: conversationId,
    };
    dispatch(
      getConversationById({
        ...params,
        cb(res) {
          let indexOf = fetchedConversations.findIndex(
            (item) => item._id == conversationId
          );
          let deepcopied = [...fetchedConversations];
          deepcopied[indexOf] = {
            ...deepcopied[indexOf],
            participants: {
              ...deepcopied[indexOf]["participants"],
              lastReadIndex: res.data.data.totalMessages,
            },
          };
          dispatch(updateConversationList(deepcopied));
        },
      })
    );
  };

  const handleSearch = debounce((event) => {
    setSearchInput(event.target.value)
    setPage(1)
  }, 500)

  return (
    <div className="chatlist">
      <div className="chat-header">
        <div className="msg-search position-relative">
          <input
            type="text"
            className="form-control"
            id="inlineFormInputGroup"
            placeholder="Search by user"
            aria-label="search"
            onChange={handleSearch}
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      </div>
      <div className="modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-body">
            <div
              className="chat-lists"
              id="chatList"
            >
              <InfiniteScroll
                dataLength={fetchedConversations.length}
                next={fetchMoreData}
                hasMore={hasMore}
                height={400}
                endMessage={
                  fetchedConversations.length > pageLimit && <p style={{ textAlign: "center" }}>
                    <b>No More Conversations</b>
                  </p>
                }
                scrollableTarget="chatList"
              >
                <div className="chat-list">
                  {fetchedConversations && fetchedConversations.length > 0 ? (
                    fetchedConversations.map((ele, index) => (
                      <ListBlock
                        info={ele}
                        key={`List_${index}`}
                        activeConversation={activeConversation}
                        getAllConversation={getAllConversation}
                      />
                    ))
                  ) : (
                    <div className="noMessageDiv">No Conversations</div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
