import React from "react";
import * as Images from "../../../../../utilities/images";
import { useDispatch } from "react-redux";
import { chatClearMessage } from "../../../../../redux/slices/chat";
import { useChatSelector } from "../../../../../redux/selector/chat";

export default function MessageHeader(props) {
  const { imageArray, names } = props;
  const dispatch = useDispatch();
  const chatSelector = useChatSelector();
  const activeConversation = chatSelector?.activeConversation;

  const clearChat = () => {
    let params = {
      conversationId: activeConversation,
    };
    dispatch(
      chatClearMessage({
        ...params,
        cb(res) {
          props?.getAllMessagess(1);
        },
      })
    );
  };

  return (
    <div className="msg-head">
      <div className="row">
        <div className="col-8">
          <div className="d-flex align-items-center">
            <span className="chat-icon">
              {imageArray &&
                imageArray.length > 0 &&
                imageArray.map((imgsrc, index) => (
                  <img
                    className={`${index == 0 ? "usr-img" : "user-small-img"}`}
                    src={imgsrc ? imgsrc : Images.defaultUserImage}
                    alt="user img"
                  />
                ))}
            </span>
            <div className="flex-shrink-0">
              {imageArray &&
                imageArray.length > 0 &&
                imageArray.map((imgsrc, index) => (
                  <img
                    className={`${index == 0 ? "usr-img" : "user-small-img"}`}
                    src={imgsrc ? imgsrc : Images.defaultUserImage}
                    alt="user img"
                  />
                ))}
            </div>
            <div className="flex-grow-1 ms-3">
              <h3>{names}</h3>
            </div>
          </div>
        </div>
        <div className="col-4">
          <ul className="moreoption">
            <li className="navbar nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
              </a>
              <ul className="dropdown-menu chat_dropdown">
                {/* <li>
                  <a className="dropdown-item">Report</a>
                </li> */}
                <li>
                  <a className="dropdown-item" onClick={clearChat}>
                    Clear Chat
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
