import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../layout/DashboardLayout";
import AuthLayout from "../layout/AuthLayout";
import * as Containers from "../containers";
import PublicRoute from "../layout/PublicRoute";
import ProtectRoute from "../layout/ProtectRoute";

const Router = () => {
  return (
    <>
      <Routes>
        {/* DASHBOARD_ROUTES */}
        <Route element={<DashboardLayout />}>
          {/* <Route path="/setting" element={<SettingMain />} /> */}
          <Route
            path="/"
            element={
              <ProtectRoute>
                <Containers.Dashboard />
              </ProtectRoute>
            }
          />

          <Route
            path="/manage-account"
            element={
              <ProtectRoute>
                <Containers.ManageAccounts />
              </ProtectRoute>
            }
          />
          {/* <Route
            path="/manage-service"
            element={
              <ProtectRoute>
                <Containers.ManageOutfit />
              </ProtectRoute>
            }
          /> */}
          {/* <Route
            path="/add-service"
            element={
              <ProtectRoute>
                <Containers.AddIndustrys />
              </ProtectRoute>
            }
          /> */}
          {/* <Route
            path="/edit-service/:serviceSlug"
            element={
              <ProtectRoute>
                <Containers.AddIndustrys />
              </ProtectRoute>
            }
          /> */}
          {/* <Route
            path="/view-service/:serviceSlug"
            element={
              <ProtectRoute>
                <Containers.ServiceDetailsView />
              </ProtectRoute>
            }
          /> */}
          <Route
            path="/service"
            element={
              <ProtectRoute>
                <Containers.ManageSubServices />
              </ProtectRoute>
            }
          />
          <Route
            path="/service/add"
            element={
              <ProtectRoute>
                <Containers.ModifyService />
              </ProtectRoute>
            }
          />
          <Route
            path="/service/edit/:serviceSlug"
            element={
              <ProtectRoute>
                <Containers.ModifyService />
              </ProtectRoute>
            }
          />
          <Route
            path="/service/view/:serviceSlug"
            element={
              <ProtectRoute>
                <Containers.ServiceDetailsView />
              </ProtectRoute>
            }
          />

          <Route
            path="/privacy"
            element={
              <ProtectRoute>
                <Containers.Contact />
              </ProtectRoute>
            }
          />

          <Route
            path="/support"
            element={
              <ProtectRoute>
                <Containers.Support />
              </ProtectRoute>
            }
          />

          <Route
            path="/bookings"
            element={
              <ProtectRoute>
                <Containers.Bookings />
              </ProtectRoute>
            }
          />

          <Route
            path="/transactions"
            element={
              <ProtectRoute>
                <Containers.Transactions />
              </ProtectRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <ProtectRoute>
                <Containers.MessageContainer />
              </ProtectRoute>
            }
          />

          <Route
            path="manage-account/view-accounts/:id"
            element={
              <ProtectRoute>
                <Containers.AccountsDetailsView />
              </ProtectRoute>
            }
          />
        </Route>

        {/* AUTH_ROUTES */}
        <Route element={<AuthLayout />}>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Containers.Login />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <Containers.ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/enter-otp"
            element={
              <PublicRoute>
                <Containers.EnterOtp />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <Containers.ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/delete-account"
            element={
              <PublicRoute>
                <Containers.DeleteAccount />
              </PublicRoute>
            }
          />

          <Route path="*" element={<Containers.PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
