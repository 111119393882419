const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "/users/login",
    DELETE_ACCOUNT: "/users/delete-account",
    FORGOT_PASSWORD: "/users/forgot-password",
    RESET_PASSWORD: "users/reset-password",
    LOGOUT_ADMIN: "/users/logout",
    ENTER_OTP: "/users/reset-password-otp-verify",
    RSEND_ENTER_OTP: "/users/resend",
  },
  webApiPath: {
    DASHBOARD: "users/dashboard",
    ADD_FILE: "/users/upload-file",
    GET_ALL_USERS: "/users",
    GET_ONE_USERS: "users/user-profile/",
    GET_ALL_INDUSTRY: "/services",
    GET_INDUSTRY_BY_ID: "/services",
    ADD_INDUSTRY: "/services",
    DELETE_INDUSTRY: "/services",
    UPDATE_ACTIVE_INACTIVE_STATUS: "users/status",
    VERIFY_DOCUMENT: "/users/verify/documents/",
    HELPER_PAGES: "/helperPages/all/",
    SINGLE_HELPER_PAGES: "/helperPages/get-one",
    UPDATE_HELPER_PAGES: "/helperPages/update",
    UPDATE_HELPER_PAGE_STATUS: "/helperPages/update-status",
    DELETE_HELPER_PAGE: "/helperPages/delete/",
    STATS_DATA: "/users/dashboard",
    GET_PROVIDERS_SERVICES: "/users/subservices",
    GET_ALL_BOOKINGS: "/bookings/admin",
    GET_ALL_JOBS: "/jobs/admin",
    SUBSERVICES_UPDATE: "/subservices",
    SUPPORT: "/support/"
  },
  contentManagement: {
    PRIVACY_POLICY: "/helperPages/bySlug/",
    PRIVACY_UPDATE: "/helperPages/",
  },
  chatApiPath: {
    GET_CONVERSATION_LIST: "/conversation",
    GET_CONVERSATION_DETAIL: "/conversation/",
    MARK_READ_MESSAGE: "/conversation/markRead/",
    GET_MESSAGE_LIST: "/messages/",
    SEND_MESSAGE: "/messages/send/",
    CLEAR_MESSAGE: "/messages/clear/"
  },
  paymentApiPath: {
    GET_PAYMENT_LIST: "/payments/"
  },
  category: {
    ADD_CATEGORIES: "/category/",
    UPDATE_CATEGORIES: "/category/",
    GET_ALL_CATEGORIES: "/category/",
    GET_CATEGORIES_BY_ID: "/category/",
    DELETE_CATEGORIES: "/category/",
  },
  service: {
    GET_ALL_SERVICE: '/subservices/',
    ADD_SERVICES: "/subservices/",
    UPDATE_SERVICE: "/subservices/",
    DELETE_SERVICE: "/subservices/",
    GET_SERVICE_BY_ID: "/subservices/"
  },
  masterData: {
    GET_MASTERDATA_BY_TYPE: '/masterdata/type',
    GET_ALL_MASTERDATA: '/masterdata',
    UPDATE_MASTERDATA: '/masterdata/',
  }
};

export default ApiPath;
