import React from "react";
import { Link } from "react-router-dom";
import * as images from "../../src/utilities/images";
import { ReactComponent as TxnIcon } from "../public/images/transaction.svg";
import { ReactComponent as ChatIcon } from "../public/images/chatIcon.svg";
import { ReactComponent as BookingIcon } from "../public/images/booking_icon.svg";
import useMediaQuery from "@mui/material/useMediaQuery";




const Sidebar = () => {
  const pathName = window.location.pathname;
  const getScreenSize = useMediaQuery("(max-width:991px)");

  const handleSidebarClose = () => { 
    let sidebarElement = document.getElementById('sidebar-mini');
    sidebarElement?.classList?.remove('sidebar-open');
    sidebarElement?.classList?.add('sidebar-close');
    
  }
  return (
    <div>
      <aside className=" sidebarSil main-sidebar sideBar-bg sidebar-dark-primary elevation-4 main">
        <div className="sidebar_brand">
          <Link to="/" className="brand-link">
            <img
              src={images.OrigoLogo}
              className="brand_logo"
              alt="Brand Logo"
              style={{ width: "100px" }}
            />
          </Link>
          <div
            data-widget={getScreenSize ? "pushmenu" : ""}
            onClick={() => handleSidebarClose()}
          >
            <i
              className="fas fa-bars sidebar_toggle d-lg-none d-sm-block"
            ></i>
          </div>
        </div>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
            >
              <li className="nav-item sideheading">
                <Link
                  to="/"
                  className={
                    ["/"].includes(pathName) ? "nav-link active" : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-th"></i>
                  <p>Dashboard</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  to="/manage-account"
                  className={
                    pathName.startsWith("/manage-account")
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-users"></i>
                  <p>Manage Accounts</p>
                </Link>
              </li>
              {/* <li className="nav-item sideheading">
                <Link
                  to="/manage-service"
                  className={
                    ["/manage-service"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  <p>Service</p>
                </Link>
              </li> */}
              <li className="nav-item sideheading">
                <Link
                  to="/service"
                  className={
                    pathName.startsWith("/service")
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  <p>Services</p>
                </Link>
              </li>

              <li className="nav-item sideheading">
                <Link
                  to="/bookings"
                  className={
                    ["/bookings"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <BookingIcon />
                  <p className="ms-1">Bookings</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  to="/privacy"
                  className={
                    ["/privacy"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-info"></i>
                  <p>CMS</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  to="/support"
                  className={
                    ["/support"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i class="nav-icon fa-solid fa-file-pen"></i>
                  <p>Support</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  to="/messages"
                  className={
                    ["/messages"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <ChatIcon />
                  <p className="ms-1">Messages</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  to="/transactions"
                  className={
                    ["/transactions"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <TxnIcon />
                  <p>Transactions</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
