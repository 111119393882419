import React, { memo, useEffect } from 'react'

import ChatList from './ChatList';
import MesssageScreens from './MessageScreen';
import { useDispatch } from 'react-redux';
import { useChatSelector } from '../../../redux/selector/chat';
import { useAuthSelector } from '../../../redux/selector/auth';
import { resetConversation } from '../../../redux/slices/chat';


const ChatListMemoized = memo((props) => <ChatList {...props} />);

export default function MessageContainer() {
    const chatSelector = useChatSelector()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(resetConversation())
        return () => {
            dispatch(resetConversation())
        }
    }, [])
    return (
        <div className="content-wrapper adminUsers">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 headingMain">Messages</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="message-area pt-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="chat-area">
                                <ChatListMemoized />
                                <div className="chatbox">
                                    <div className="modal-dialog-scrollable">
                                        <div className="modal-content">
                                            {chatSelector.activeConversation ? <MesssageScreens /> :
                                                <div className='d-flex justify-content-center my-auto fw-bold'> No Active Conversation</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}