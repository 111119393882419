import { all, call, put, take, takeLatest } from "redux-saga/effects"
import { ApiClient } from "../../../utilities/api"
import ApiPath from "../../../constants/apiPath"
import { setAllTransactions, setTransactionById, setTransactionShare, shareUpdated } from "../../slices/transaction"
import { onErrorStopLoad } from "../../slices/auth"
import { toast } from "react-toastify"

function* getAllTransactions(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.get, action.url = ApiPath.paymentApiPath.GET_PAYMENT_LIST, (action.params = { params: dataToSend }))
        if (resp.status) {
            yield call(action.payload.cb, (action.res = resp))
            yield put(setAllTransactions(action.payload))
        } else {
            throw resp;
        }
    } catch (error) {
        let errorReponse = {}
        yield put(onErrorStopLoad())
        if (error.response.data.message.includes('not found')) {
            errorReponse = {
                notFound: true
            }
        }
        yield call(action.payload.cb, (action.res = errorReponse))
        // toast.error(error?.response?.data?.message);
    }
}
function* getTransactionShare(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.cb;
        const resp = yield call(ApiClient.get, action.url = ApiPath.masterData.GET_MASTERDATA_BY_TYPE, (action.params = { params: dataToSend }))
        if (resp.status) {
            yield call(action.payload.cb, (action.res = resp))
            yield put(setTransactionShare(resp.data.data))
        } else {
            throw resp;
        }
    } catch (error) {
        let errorReponse = {}
        yield put(onErrorStopLoad())
        if (error.response.data.message.includes('not found')) {
            errorReponse = {
                notFound: true
            }
        }
        yield call(action.payload.cb, (action.res = errorReponse))
        toast.error(error?.response?.data?.message);
    }
}

function* getTransactionById(action) {
    try {
        const resp = yield call(ApiClient.get, action.url = "txn/id")
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
            yield put(setTransactionById(action))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad())
        yield call(action.payload.cb, (action.res = {}))
        toast.error(error?.response?.data?.message)
    }
}
function* updateTransactionShare(action) {
    try {
        let dataToSend = { ...action.payload };
        delete dataToSend.id;
        delete dataToSend.cb;
        const resp = yield call(ApiClient.put, `${ApiPath.masterData.UPDATE_MASTERDATA}${action.payload.id}`, dataToSend);
        if (resp.status) {
            yield call(action.payload.cb, (action.payload = resp))
            yield put(shareUpdated(action.payload.data))
        } else {
            throw resp;
        }
    } catch (error) {
        yield put(onErrorStopLoad());
        yield call(action.payload.cb, (action.res = {}));
        toast.error(error.response.data.message);
    }
}

function* txnSaga() {
    yield all([takeLatest("transaction/getAllTransactions", getAllTransactions)])
    yield all([takeLatest("transaction/getTransactionShare", getTransactionShare)])
    yield all([takeLatest("transaction/getTransactionById", getTransactionById)])
    yield all([takeLatest("transaction/updateTransactionShare", updateTransactionShare)])
}


export default txnSaga