import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../redux/slices/auth";
import { getDocumentTitle } from "../utilities/helper";


const ProtectRoute = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem("adminAuthToken"));
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    const token = localStorage.getItem("adminAuthToken");
    if (token) {
      const decoded = jwtDecode(token);
      dispatch(setLoggedInUser(decoded))
    }
  }, [])

  useEffect(() => {
    const title = getDocumentTitle(location?.pathname)
    document.title = title
  }, [location.pathname])
  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectRoute;
