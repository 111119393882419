import React, { useEffect } from "react";
import { useAuthSelector } from "../../../../redux/selector/auth";
import * as Images from "../../../../utilities/images";
import { getTimeDiffOutput } from "../../../../utilities/helper";

export default function ListBlock(props) {
  const { info, key, activeConversation } = props;
  const authSelector = useAuthSelector();
  let currentUser = authSelector.loggedInUser;
  let {
    participantsDetails,
    lastMessage,
    totalMessages,
    participants,
    _id,
    updatedAt,
  } = info;
  let nameArray = [];
  let imageArray = [];
  participantsDetails.forEach((item) => {
    if (item?._id !== currentUser?.user_id) {
      nameArray.push(item?.userInfo?.name);
      imageArray.push(item?.userInfo?.profilePhoto);
    }
  });
  let names = nameArray?.join(", ");
  let unreadMessage = totalMessages - participants.lastReadIndex;

  return (
    <span
      className="d-flex align-items-center msg-chatBox "
      key={key}
      onClick={() => activeConversation(_id)}
    >
      <div className="flex-shrink-0">
        {imageArray &&
          imageArray?.length > 0 &&
          imageArray?.map((imgsrc, index) => (
            <img
              className={`${index == 0 ? "usr-img" : "user-small-img"}`}
              src={imgsrc ? imgsrc : Images?.defaultUserImage}
              alt="user img"
            />
          ))}
      </div>
      <div className="flex-grow-1 ms-3">
        <h3 className="cursor-pointer" >{names}</h3>
        <p className="cursor-pointer" >{lastMessage && lastMessage?.message ? lastMessage?.message : ""}</p>
      </div>
      <div className="msg-time-count">
        <p className="msg-time">{getTimeDiffOutput(updatedAt)}</p>
        {unreadMessage !== 0 && unreadMessage > 0 && <p className="msg-count">{unreadMessage}</p>}
      </div>
    </span>
  );
}

