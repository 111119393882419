import { all, call, put, takeLatest } from "redux-saga/effects";
import { ApiClient } from "../../../utilities/api";
import {
  clearMessage,
  onErrorStopLoad,
  setAllConversations,
  setAllMessages,
  setChatClearMessage,
  setConversationById,
  setMoreConversations,
  setNewMessage,
  setOneAllMessage,
} from "../../slices/chat";
import ApiPath from "../../../constants/apiPath";
import { toast } from "react-toastify";

function* getAllConversation(action) {
  let dataToSend = { ...action.payload }
  delete dataToSend.cb
  try {
    const resp = yield call(
      ApiClient.get,
      action.url = `${ApiPath.chatApiPath.GET_CONVERSATION_LIST}`,
      (action.params = { params: dataToSend })
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.payload = resp));
      yield put(setAllConversations(action.payload));
    } else {
      throw resp;
    }
  } catch (error) {
    yield put(onErrorStopLoad());
    yield put(setAllConversations({}));
    yield call(action.payload.cb, (action.res = {}));
    // toast.error(error.response.data.message);
  }
}

function* getMoreConversation(action) {
  let dataToSend = { ...action.payload }
  delete dataToSend.cb
  try {
    const resp = yield call(
      ApiClient.get,
      action.url = `${ApiPath.chatApiPath.GET_CONVERSATION_LIST}`,
      (action.params = { params: dataToSend })
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.payload = resp));
      yield put(setMoreConversations(action.payload));
    } else {
      throw resp;
    }
  } catch (error) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));
    // toast.error(error.response.data.message);
  }
}

function* getConversationById(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.chatApiPath.GET_CONVERSATION_DETAIL}${action.payload.conversationId}`)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.payload = resp));
      yield put(setConversationById(action.payload));
    } else {
      throw resp;
    }
  } catch (error) {
    yield call(action.payload.cb, (action.res = {}));
    yield put(onErrorStopLoad());
    toast.error(error.response.data.message);
  }
}

function* getAllMessages(action) {

  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.chatApiPath.GET_MESSAGE_LIST}${action.payload.conversationId}?page=${action.payload.page}&limit=${action.payload.limit}`)
    );


    if (resp.status == 200) {
      yield call(action.payload.cb, (action.payload = resp));
    
      if (action?.payload?.page === 1) {
        yield put(setOneAllMessage(action.payload));
      } else {
        yield put(setAllMessages(action.payload));
      }
      // yield put(setAllMessages(action.payload));
    } else {
      throw resp;
    }
  } catch (error) {
    yield put(clearMessage());
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));
    // toast.error(error.response.data.message);
  }
}
function* getOneAllMessages(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.chatApiPath.GET_MESSAGE_LIST}${action.payload.conversationId}?page=${action.payload.page}&limit=${action.payload.limit}`)
    );
    if (resp.status == 200) {
      yield call(action.payload.cb, (action.payload = resp));
      yield put(setOneAllMessage(resp));
    } else {
      throw resp;
    }
  } catch (error) {
    yield put(clearMessage());
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));
    // toast.error(error.response.data.message);
  }
}
function* sendMessage(action) {
  try {
    let dataToSend = {
      message: action.payload.message,
    };
    if (action.payload.image) {
      dataToSend.image = action.payload.image;
    }
    const resp = yield call(
      ApiClient.post,
      (action.url = `${ApiPath.chatApiPath.SEND_MESSAGE}${action.payload.conversationId}`),
      dataToSend
    );

    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));

      yield put(setNewMessage(resp.data.data));
    } else {
      throw resp;
    }
  } catch (error) {
    yield put(onErrorStopLoad());
    yield put(setNewMessage({}));
    yield call(action.payload.cb, (action.res = {}));
  }
}

function* chatClearMessage(action) {
  try {
    const resp = yield call(
      ApiClient.get,
      (action.url = `${ApiPath.chatApiPath.CLEAR_MESSAGE}${action.payload.conversationId}`)
    );
    if (resp.status) {
      yield call(action.payload.cb, (action.payload = resp));
      yield put(setChatClearMessage(action.payload));
    } else {
      throw resp;
    }
  } catch (error) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));
    yield put(setChatClearMessage({}));
    toast.error(error.response.data.message);
  }
}

function* markRead(action) {
  try {
    const resp = yield call(ApiClient.get, (action.url = `${ApiPath.chatApiPath.MARK_READ_MESSAGE}${action.payload.conversationId}`));

    if (resp.status) {
      yield call(action.payload.cb, (action.res = resp));
    } else {
      throw resp;
    }

  } catch (error) {
    yield put(onErrorStopLoad());
    yield call(action.payload.cb, (action.res = {}));
  }
}

function* chatSaga() {
  yield all([takeLatest("chat/getAllConversation", getAllConversation)]);
  yield all([takeLatest("chat/getMoreConversation", getMoreConversation)]);
  yield all([takeLatest("chat/getConversationById", getConversationById)]);
  yield all([takeLatest("chat/getAllMessages", getAllMessages)]);
  yield all([takeLatest("chat/getOneAllMessages", getOneAllMessages)]);
  yield all([takeLatest("chat/sendMessage", sendMessage)]);
  yield all([takeLatest("chat/markRead", markRead)]);
  yield all([takeLatest("chat/chatClearMessage", chatClearMessage)]);
}

export default chatSaga;
