import React, { useEffect, useState } from "react";
import * as Images from "../../../utilities/images.js";
import {
  addIndustry,
  editIndustry,
  getIndustryById,
  uploadFile,
} from "../../../redux/slices/web/index.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomModal from "../../Modal/CustomModal.js";
import CreateRecrutingModal from "./createRecrutingModal.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FullPageloader from "../../../common/FullPageloader.jsx";
import { decodeData } from "../../../utilities/helper.js";
import AdditionLawnArea from "./additionLawnArea.jsx";
import Accordion from "react-bootstrap/Accordion";

const AddIndustrys = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState(Math.random());
  const location = useLocation();
  const queryParams = useParams();
  const isUpdating = location?.state?.edit || false;
  const industryById = useSelector((state) => state.web.industryById);
  const loading = useSelector((state) => state.web.loading);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setRecurringEditIndex(null);
    setRecurringEditData(null);
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };
  const handlerecurringOptionsRemove = (index) => {
    setRecurringOptions((prev) => {
      const List = [...prev];
      return List.filter((item, i) => {
        return index != i;
      });
    });
  };
  const [name, setName] = useState("");
  const [subServiceEditIndex, setSubServiceEditIndex] = useState(null);
  const [addOnEditIndex, setAddOnEditIndex] = useState(null);
  const [areaEditIndex, setAreaEditIndex] = useState(null);
  const [recurringEditIndex, setRecurringEditIndex] = useState(null);
  const [recurringEditData, setRecurringEditData] = useState(null);
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [types, setTypes] = useState([]);
  const [addOnTypes, setAddOnTypes] = useState([]);
  const [addOnTitle, setAddOnTitle] = useState("");
  const [addOnDescription, setAddOnDescription] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);
  const [isAreaNeeded, setIsAreaNeeded] = useState(false);
  const [recurringOptions, setRecurringOptions] = useState([]);
  const [subServiceInfo, setSubserviceInfo] = useState({
    title: "",
    price: "",
    description: "",
    image: null,
  });
  const [addOnTypeInfo, setAddOnTypeInfo] = useState({
    title: "",
    price: "",
    description: "",
  });
  const [lawnAreas, setLawnArea] = useState([]);
  const [lawnData, setLawnData] = useState({
    value: "",
    price: "",
    discountPercentage: "",
    discountedPrice: "",
  });

  const [stepError, setStepError] = useState({
    step1: true,
    step2: false,
    step3: true,
    step4: true,
  })
  const clearAddonFields = () => {
    setAddOnTypeInfo({ title: "", price: 0, description: "" });
  };
  const clearSubServiceFields = () => {
    setAreaEditIndex(null);
    setLawnArea([]);
    setIsAreaNeeded(false);
    setSubserviceInfo({ title: "", price: "", description: "", image: null });
    setSubServiceEditIndex(null);
    setLawnData({
      value: "",
      price: "",
      discountPercentage: "",
      discountedPrice: "",
    });
  };
  const handleAddType = () => {
    if (subServiceInfo?.title === "") {
      toast.error("Enter Name for Sub-Service");
    } else if (
      !isAreaNeeded &&
      (subServiceInfo?.price === 0 || !subServiceInfo?.price)
    ) {
      toast.error("Enter Price for Sub-Service");
    } else if (subServiceInfo?.description === "") {
      toast.error("Enter description for Sub-Service");
    } else if (subServiceInfo?.image === null) {
      toast.error("Select image for Sub-Service");
    } else if (isAreaNeeded && lawnAreas.length == 0) {
      toast.error("Enter Atleast one of the area options");
    } else {
      if (subServiceEditIndex !== null) {
        setTypes((prev) => {
          let typeArea = [...prev];
          let newData = { ...subServiceInfo };
          if (isAreaNeeded) newData.area = lawnAreas;
          if (isAreaNeeded) newData.price = 0;
          typeArea[subServiceEditIndex] = newData;
          return typeArea;
        });
      } else {
        setTypes((prev) => {
          let newData = { ...subServiceInfo };
          if (isAreaNeeded) newData.area = lawnAreas;
          if (isAreaNeeded) newData.price = 0;
          return [...prev, newData];
        });
      }
      clearSubServiceFields();
    }
  };
  const handleRemoveType = (index) => {
    setTypes((prev) => {
      const List = [...prev];
      return List.filter((item, i) => {
        return index != i;
      });
    });
  };

  const handleServiceImage = (event) => {
    let params = {
      file: event.target.files[0],
    };
    var response = dispatch(
      uploadFile({
        ...params,
        cb(res) {
          setImage(res?.data?.data?.fileUrl);
        },
      })
    );
  };

  const handleAddOnTypeAdd = (e) => {
    if (addOnTypeInfo?.title === "") {
      toast.error("Enter Name for Add-On");
    } else if (addOnTypeInfo?.price === 0 || !addOnTypeInfo?.price) {
      toast.error("Enter Price for Add-ON");
    } else if (addOnTypeInfo?.description === "") {
      toast.error("Enter description for Add-ON");
    } else {
      if (addOnEditIndex !== null) {
        setAddOnTypes((prev) => {
          let addons = [...prev];
          addons[addOnEditIndex] = addOnTypeInfo;
          return addons;
        });
        setAddOnEditIndex(null);
      } else {
        setAddOnTypes((prev) => [...prev, addOnTypeInfo]);
      }
      setAddOnTypeInfo({
        title: "",
        price: "",
        description: "",
      });
    }
  };
  const handleAddOnTypeRemove = (index) => {
    setAddOnTypes((prev) => {
      const List = [...prev];
      return List.filter((item, i) => {
        return index != i;
      });
    });
  };

  const handleSubServiceImage = (event) => {
    let params = {
      file: event.target.files[0],
    };
    dispatch(
      uploadFile({
        ...params,
        cb(res) {
          setSubserviceInfo((prev) => ({
            ...prev,
            image: res?.data?.data?.fileUrl,
          }));
        },
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name == "") {
      toast.error("Service name is Required");
      setStepError((prev) => ({
        ...prev,
        step1: true,
      }));
    } else if (description == "") {
      toast.error("Service Description is Required");
      setStepError((prev) => ({
        ...prev,
        step1: true,
      }));
    } else if (image == null) {
      toast.error("Service Image is Required");
      setStepError((prev) => ({
        ...prev,
        step1: true,
      }));
    } else if (isRecurring == true && recurringOptions.length == 0) {
      toast.error("Add atleast one recurrsing option");
      setStepError((prev) => ({
        ...prev,
        step1: false,
        step2: true,
      }));
    } else if (types.length == 0) {
      toast.error("Add atleast one Subservice Option");
      setStepError((prev) => ({
        ...prev,
        step1: false,
        step1: false,
        step3: true,
      }));
    } else if (
      subServiceEditIndex !== null ||
      addOnEditIndex !== null ||
      areaEditIndex !== null ||
      recurringEditIndex !== null
    ) {
      toast.info("You have some unsaved changes");
    } else {
      const params = {
        name,
        description,
        image,
        isRecurring,
        types,
        price,
      };
      if (isRecurring) {
        params["recurringOptions"] = recurringOptions;
      }
      if (
        addOnTitle &&
        addOnTitle.trim() !== "" &&
        addOnDescription &&
        addOnDescription.trim() != "" &&
        addOnTypes &&
        addOnTypes.length > 0
      ) {
        const addOns = [
          {
            title: addOnTitle,
            description: addOnDescription,
            types: addOnTypes,
          },
        ];
        params.addOns = addOns;
      }
      if (isUpdating) {
        let updateParams = { ...params, _id: industryById._id };
        updateParams.types = types.map((item) => {
          let data = { ...item };
          let areaArray = [];
          if (item.area && item.area.length > 0) {
            areaArray = item.area.map((item) => {
              let data = { ...item };
              delete data.unit;
              delete data._id;
              return data;
            });
            data.area = areaArray;
          } else {
            delete data.area;
          }
          delete data._id;
          return data;
        });
        if (addOnTypes && addOnTypes.length > 0) {
          updateParams.addOns[0]["types"] = addOnTypes.map((item) => {
            let data = { ...item };
            delete data._id;
            return data;
          });
        }
        if (isRecurring)
          updateParams.recurringOptions = recurringOptions.map((item) => {
            let data = { ...item };
            delete data._id;
            return data;
          });
        dispatch(
          editIndustry({
            ...updateParams,
            cb(res) {
              if (res.status === 200) {
                toast.success("Industry Updated Successfully");
                navigate("/manage-service");
              } else {
                toast.error("Something went wrong");
              }
            },
          })
        );
      } else {
        dispatch(
          addIndustry({
            ...params,
            cb(res) {
              if (res.status === 200) {
                toast.success("New Industry Added");
                navigate("/manage-service");
              }
            },
          })
        );
      }
    }
  };

  const setInititalValues = (dataById) => {
    setName(dataById.name);
    setPrice(dataById.price);
    setDescription(dataById.description);
    setImage(dataById.image);
    setTypes(dataById.types);
    setIsRecurring(dataById.isRecurring);
    if (dataById.isRecurring) setRecurringOptions(dataById.recurringOptions);
    if (dataById.addOns && dataById.addOns.length > 0) {
      setAddOnTitle(dataById.addOns[0]["title"]);
      setAddOnDescription(dataById.addOns[0]["description"]);
      setAddOnTypes(dataById.addOns[0]["types"]);
    }
    setStepError({
      step1: false,
      step2: false,
      step3: false,
      step4: false,
    });
  };

  useEffect(() => {
    if (queryParams && queryParams.serviceSlug) {
      const params = {
        id: decodeData(queryParams.serviceSlug),
      };
      dispatch(
        getIndustryById({
          ...params,
          cb(response) {
            if (response.status === 200) {
              setInititalValues(response.data.data);
            }
          },
        })
      );
    }
  }, [queryParams]);

  const handleAddLawn = (action, data) => {
    if (action === "add") {
      setLawnArea((prev) => [...prev, data]);
    } else if (action === "edit") {
      setLawnArea((prev) => {
        let newList = [...prev];
        newList[areaEditIndex] = data;
        return newList;
      });
      setAreaEditIndex(null);
    }
  };
  const handleRemoveLawn = (index) => {
    setLawnArea((prev) => {
      return prev.filter((i, ind) => ind !== index);
    });
  };

  const AreaDiscountBlock = (props) => {
    const { data, hideActions } = props;
    return (
      <div className="mt-3 w-100">
        <span className="addOtherCategory"> Lawn Area</span>
        {data &&
          data.map((ele, index) => (
            <AreaInnerList
              hideActions={hideActions}
              element={ele}
              key={`lawn_saved_${index}`}
            />
          ))}
      </div>
    );
  };

  const handleArea = (action, areaIndex, areaObj) => {
    if (action === "edit") {
      setAreaEditIndex(areaIndex);
      setLawnData({
        value: areaObj.value,
        price: areaObj.price,
        discountPercentage: areaObj.discountPercentage,
        discountedPrice: areaObj.discountedPrice,
      });
    } else if (action === "remove") {
      setLawnArea((prev) => {
        return prev.filter((item, index) => index !== areaIndex);
      });
    }
  };

  const AreaInnerList = (props) => {
    const { element, key, index, hideActions } = props;
    return (
      <div className="lawnArea" key={key}>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-3">
            <span className="fw-bold">{element.value} sq. ft</span>
            <span>$ {element.discountedPrice}</span>
          </div>
          {hideActions ? (
            <span>{element.discountPercentage}% off</span>
          ) : (
            <div className="area_edit_block">
              <span>{element.discountPercentage}% off</span> |
              <img
                src={Images.EditIcon}
                className="cursor-pointer"
                onClick={() => {
                  handleArea("edit", index, element);
                }}
              />
              <img
                src={Images.PlusIcon_}
                className="cursor-pointer"
                onClick={() => {
                  handleArea("remove", index);
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleSubServiceEdit = (data, index) => {
    setSubServiceEditIndex(index);
    setSubserviceInfo({
      title: data.title,
      price: data.price,
      description: data.description,
      image: data.image,
    });
    setIsAreaNeeded(data?.area?.length > 0 ? true : false);
    setLawnArea(data.area || []);
    scrollTo("subservicerow");
  };

  const handleAddonEdit = (data, index) => {
    setAddOnEditIndex(index);
    setAddOnTypeInfo({
      title: data.title,
      price: data.price,
      description: data.description,
    });
    scrollTo("addOnRow");
  };

  const recurringEdit = (data, index) => {
    setRecurringEditIndex(index);
    setRecurringEditData(data);
    setModalDetail({
      show: true,
      flag: "createRecruting",
    });
    setKey(Math.random());
  };

  const submitRecurring = (values) => {
    if (recurringEditIndex !== null) {
      setRecurringOptions((prev) => {
        let options = [...prev];
        options[recurringEditIndex] = values;
        return options;
      });
      setRecurringEditData(null);
      setRecurringEditIndex(null);
    } else {
      setRecurringOptions((prev) => [...prev, { ...values }]);
    }
  };
  const scrollTo = (elementId) => {
    if (!elementId) return;
    const offset = 100;
    const element = document.getElementById(elementId);
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  const handlPrice = (e) => {
    const value = e.target.value;

    if (value >= 0) {
      if (value === "0" && !isAreaNeeded) {
        toast.error("Price should not be zero");
      } else {
        setSubserviceInfo((prev) => ({
          ...prev,
          price: value,
        }));
      }
    }
  };

  return (
    <>
      <div className="content-wrapper adminUsers">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Add Services</h1>
              </div>
            </div>
          </div>
        </div>
        {loading && <FullPageloader />}
        <section className="addIndustrySection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <form onSubmit={handleSubmit}>
                  <Accordion defaultActiveKey={"step1"}>
                    <Accordion.Item
                      eventKey="step1"
                      className="editService_AccordtionItem"
                    >
                      <Accordion.Header>
                        Service Details
                        {stepError.step1 && (
                          <span style={{ color: "red" }} className="mx-2">
                            *
                          </span>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label customLables">
                              Service Name
                            </label>
                            <input
                              type="text"
                              className="form-control customControl"
                              placeholder="Enter Service Name"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label customLables">
                            Description
                          </label>
                          <textarea
                            className="form-control customControl"
                            placeholder="Write here..."
                            value={description}
                            rows={6}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          ></textarea>
                        </div>
                        <div className="file-input mb-3">
                          <span className="d-block customLables mb-2">
                            Service Image
                          </span>
                          {image ? (
                            <>
                              <img src={image} className="imagePreview" />
                              <span
                                className="crossIcon____"
                                onClick={() => {
                                  setImage(null);
                                }}
                              >
                                <img
                                  src={Images.PlusIcon_}
                                  className="plusIcon______"
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              {" "}
                              <input
                                type="file"
                                name="file-input"
                                id="file-input"
                                className="file-input__input"
                                onChange={handleServiceImage}
                              />
                              <label
                                className="file-input__label"
                                htmlFor="file-input"
                              >
                                <p className="dragText">
                                  {" "}
                                  <img src={Images.UploadImg} /> <br />
                                  Drag image here or{" "}
                                  <span className="browserTxt">Browse</span>
                                  <br />
                                  <span className="uploadCoverText">
                                    Upload cover image
                                  </span>
                                </p>
                              </label>
                            </>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="step2"
                      className="editService_AccordtionItem"
                    >
                      <Accordion.Header>
                        Recurring Options
                        {stepError.step2 && (
                          <span style={{ color: "red" }} className="mx-2">
                            *
                          </span>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mb-3">
                          <span className="customLables d-block mb-3">
                            Service Type
                          </span>
                          <div className="d-inline-block me-4">
                            <input
                              type="radio"
                              id="test1"
                              name="radio-group"
                              checked={!isRecurring}
                              readOnly
                              onClick={() => {
                                setIsRecurring(false);
                              }}
                            />
                            <label htmlFor="test1">One Time</label>
                          </div>
                          <div className="d-inline-block">
                            <input
                              type="radio"
                              id="test2"
                              name="radio-group"
                              checked={isRecurring}
                              readOnly
                              onClick={() => {
                                setIsRecurring(true);
                                if (recurringOptions.length === 0) {
                                  setModalDetail({
                                    show: true,
                                    flag: "createRecruting",
                                  });
                                  setKey(Math.random());
                                }
                              }}
                            />
                            <label htmlFor="test2">Recurring</label>
                          </div>
                        </div>
                        {isRecurring && (
                          <div className="addSubServiceOuter my-4">
                            <table className="addServiceTable">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Frequency</th>
                                  <th>Discount</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {recurringOptions.map((item, i) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{item?.title}</td>
                                        <td>{item?.frequency}</td>
                                        <td>{item?.discountPercentage}% off</td>
                                        <td className="d-flex gap-2">
                                          <img
                                            className="cursor-pointer"
                                            src={Images.EditIcon}
                                            onClick={() => {
                                              recurringEdit(item, i);
                                            }}
                                          />
                                          <img
                                            className="cursor-pointer"
                                            src={Images.DeleteIcon}
                                            onClick={() => {
                                              handlerecurringOptionsRemove(i);
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                            <button
                              type="button"
                              className="cancelBtn recurringbtn mt-4"
                              onClick={() => {
                                setModalDetail({
                                  show: true,
                                  flag: "createRecruting",
                                });
                                setKey(Math.random());
                              }}
                            >
                              Add more recurring
                            </button>
                          </div>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="step3"
                      className="editService_AccordtionItem"
                    >
                      <Accordion.Header>
                        Sub Services
                        {stepError.step3 && (
                          <span style={{ color: "red" }} className="mx-2">
                            *
                          </span>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="addSubService">
                          <span className="addOtherCategory">
                            Add Sub Services
                          </span>
                        </div>
                        <div className="addSubServiceOuter mt-4">
                          <div className="row mb-4">
                            {/* added sub service list */}
                            {types?.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div className="col-md-6  mb-3">
                                    <div className="smallLawnOuter">
                                      <img
                                        src={Images.PlusIcon_}
                                        className="plusIcon_"
                                        onClick={() => {
                                          handleRemoveType(index);
                                        }}
                                      />
                                      <img
                                        src={Images.EditIcon}
                                        className="editIcon_"
                                        onClick={() => {
                                          handleSubServiceEdit(item, index);
                                        }}
                                      />
                                      <div className="d-flex">
                                        <div className="smallLawnImg">
                                          <img
                                            src={item.image ?? Images.SmallLawn}
                                          />
                                        </div>
                                        <div className="smallLawnData">
                                          <h4 className="smallLawnHeading mb-1">
                                            {item?.title}
                                          </h4>
                                          <p className="mb-2">
                                            {item?.description}
                                          </p>
                                          <h6>
                                            {item.price !== 0 &&
                                              `$${item?.price}`}
                                          </h6>
                                        </div>
                                      </div>
                                      {item.area && item.area.length > 0 && (
                                        <AreaDiscountBlock
                                          data={item.area}
                                          hideActions={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row" id="subservicerow">
                            <div className="mb-3 col-md-6">
                              <label className="form-label customLables">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control customControl"
                                placeholder="Enter Name"
                                value={subServiceInfo?.title}
                                onChange={(e) => {
                                  setSubserviceInfo((prev) => ({
                                    ...prev,
                                    title: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label className="form-label customLables">
                                Price
                              </label>
                              <div className="d-flex align-items-center">
                                <span className="dollar">$</span>
                                <input
                                  type="number"
                                  className="form-control customControl"
                                  placeholder="Enter Price"
                                  value={subServiceInfo?.price}
                                  disabled={isAreaNeeded}
                                  min="0"
                                  onChange={(e) => handlPrice(e)}
                                />
                              </div>
                            </div>
                            <div className="mb-3 col-md-12">
                              <label className="form-label customLables">
                                Description
                              </label>
                              <textarea
                                className="form-control customControl"
                                rows={6}
                                placeholder="Write here..."
                                value={subServiceInfo?.description}
                                onChange={(e) => {
                                  setSubserviceInfo((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                  }));
                                }}
                              ></textarea>
                            </div>
                            <div className="file-input mb-3 col-md-12">
                              <span className="d-block customLables mb-2">
                                Image
                              </span>
                              {subServiceInfo?.image ? (
                                <>
                                  <img
                                    src={subServiceInfo?.image}
                                    className="imagePreview"
                                  />
                                  <span
                                    className="crossIcon____"
                                    onClick={() => {
                                      setSubserviceInfo((prev) => ({
                                        ...prev,
                                        image: null,
                                      }));
                                    }}
                                  >
                                    <img
                                      src={Images.PlusIcon_}
                                      className="plusIcon______"
                                    />
                                  </span>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <input
                                    type="file"
                                    name="subServiceInfo-file-input"
                                    id="subServiceInfo-file-input"
                                    className="file-input__input"
                                    onChange={handleSubServiceImage}
                                  />
                                  <label
                                    className="file-input__label"
                                    htmlFor="subServiceInfo-file-input"
                                  >
                                    <p className="dragText">
                                      {" "}
                                      <img src={Images.UploadImg} /> <br />
                                      Drag image here or{" "}
                                      <span className="browserTxt">Browse</span>
                                      <br />
                                      <span className="uploadCoverText">
                                        Upload cover image
                                      </span>
                                    </p>
                                  </label>
                                </>
                              )}

                              <div className="mb-3 mt-5 col-md-12">
                                <div className="d-flex">
                                  <input
                                    type="checkbox"
                                    className="areaCheck mx-2"
                                    checked={isAreaNeeded}
                                    onChange={(e) =>
                                      setIsAreaNeeded(e.target.checked)
                                    }
                                  />
                                  <label className=" checkboxLabel">
                                    Do you want to add area
                                  </label>
                                </div>
                              </div>
                              {isAreaNeeded && (
                                <AdditionLawnArea
                                  areaEditIndex={areaEditIndex}
                                  InnerListBlock={AreaInnerList}
                                  lawnAreas={lawnAreas}
                                  handleAdd={handleAddLawn}
                                  handleRemove={handleRemoveLawn}
                                  setLawnData={setLawnData}
                                  lawnData={lawnData}
                                  clear={(e) => {
                                    e.preventDefault();
                                    setLawnData({
                                      value: "",
                                      price: "",
                                      discountPercentage: "",
                                      discountedPrice: "",
                                    });
                                  }}
                                />
                              )}
                            </div>
                            <button
                              className="cancelBtn me-4"
                              onClick={(e) => {
                                e.preventDefault();
                                clearSubServiceFields();
                              }}
                            >
                              CANCEL
                            </button>
                            <button
                              type="button"
                              className="addBtn"
                              onClick={() => {
                                handleAddType();
                              }}
                            >
                              {subServiceEditIndex !== null ? "Update" : "Add"}
                            </button>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item
                      eventKey="step4"
                      className="editService_AccordtionItem"
                    >
                      <Accordion.Header>
                        Service Modifiers
                        {stepError.step4 && (
                          <span style={{ color: "red" }} className="mx-2">
                            *
                          </span>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="addSubService">
                          <span className="addOtherCategory mt-4">Add On</span>
                        </div>
                        <div className="addSubServiceOuter mt-4">
                          <div className="row mb-4">
                            <div className="mb-3 col-md-12">
                              <label className="form-label customLables">
                                Name
                              </label>
                              <input
                                type="text"
                                className="form-control customControl"
                                placeholder="Enter Name"
                                onChange={(e) => setAddOnTitle(e.target.value)}
                                value={addOnTitle}
                              />
                            </div>
                            <div className="mb-5 col-md-12">
                              <label className="form-label customLables">
                                Description
                              </label>
                              <textarea
                                className="form-control customControl"
                                rows={6}
                                placeholder="Write here..."
                                onChange={(e) =>
                                  setAddOnDescription(e.target.value)
                                }
                                value={addOnDescription}
                              ></textarea>
                            </div>
                            <div className="row px-4">
                              {/* added addOnTypes   list */}
                              {addOnTypes.map((item, index) => {
                                return (
                                  <React.Fragment>
                                    <div className="col-md-6 mb-3">
                                      <div className="smallLawnOuter">
                                        <img
                                          src={Images.PlusIcon_}
                                          onClick={() => {
                                            handleAddOnTypeRemove(index);
                                          }}
                                          className="plusIcon_"
                                        />
                                        <img
                                          src={Images.EditIcon}
                                          className="editIcon_"
                                          onClick={() => {
                                            handleAddonEdit(item, index);
                                          }}
                                        />
                                        <div className="smallLawnData">
                                          <h4 className="smallLawnHeading mb-1">
                                            {item?.title}
                                          </h4>
                                          <p className="mb-2">
                                            {item?.description}
                                          </p>
                                          <h6>${item.price}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                            <div className="row" id="addOnRow">
                              <div className="mb-3 col-md-6">
                                <label className="form-label customLables">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control customControl"
                                  placeholder="Enter Name"
                                  value={addOnTypeInfo?.title}
                                  onChange={(e) => {
                                    setAddOnTypeInfo((prev) => ({
                                      ...prev,
                                      title: e.target.value,
                                    }));
                                  }}
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label customLables">
                                  Price
                                </label>
                                <div className="d-flex align-items-center">
                                  <span className="dollar">$</span>
                                  <input
                                    type="number"
                                    className="form-control customControl"
                                    placeholder="Enter Price"
                                    value={addOnTypeInfo?.price}
                                    min="0"
                                    onChange={(e) => {
                                      setAddOnTypeInfo((prev) => ({
                                        ...prev,
                                        price: e.target.value,
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="mb-3 col-md-12">
                                <label className="form-label customLables">
                                  Description
                                </label>
                                <textarea
                                  className="form-control customControl"
                                  rows={6}
                                  placeholder="Write here..."
                                  value={addOnTypeInfo?.description}
                                  onChange={(e) => {
                                    setAddOnTypeInfo((prev) => ({
                                      ...prev,
                                      description: e.target.value,
                                    }));
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <button
                            className="cancelBtn me-4"
                            onClick={(e) => {
                              e.preventDefault();
                              clearAddonFields();
                            }}
                          >
                            CANCEL
                          </button>

                          <button
                            className="addBtn"
                            type="button"
                            onClick={(e) => {
                              handleAddOnTypeAdd(e);
                            }}
                          >
                            {addOnEditIndex !== null ? "Update" : "Add"}
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div className="mt-4">
                    <button
                      className="cancelBtn me-4"
                      type="button"
                      onClick={(e) => {
                        navigate("/manage-service");
                      }}
                    >
                      CANCEL
                    </button>
                    <button className="addBtn" style={{ maxWidth: "187px" }}>
                      {isUpdating ? "Update" : "Add service"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        bigModal={modalDetail?.flag === "viewIndustry"}
        mediumWidth={false}
        className={
          modalDetail.flag === "createRecruting"
            ? "commonWidth customContent"
            : ""
        }
        ids={modalDetail.flag === "createRecruting" ? "createRecruting" : ""}
        child={
          modalDetail.flag === "createRecruting" ? (
            <CreateRecrutingModal
              close={() => handleOnCloseModal()}
              setStepError={setStepError}
              editData={recurringEditData}
              addNewRecursion={submitRecurring}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createRecruting" ? (
            <>
              <h2 className="modal_Heading">Create a recurring option</h2>
              <p onClick={handleOnCloseModal} className="modal_cancel"></p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default AddIndustrys;
