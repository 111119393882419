import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { getAllTransactions, getTransactionShare } from '../../../redux/slices/transaction'
import SpinnerGrow from '../../../common/SpinnerGrow'
import { masterDataTypes, paymentStatusClasses, paymentStatusNames } from '../../../utilities/helper'
import CustomModal from '../../Modal/CustomModal'
import modalFlags from '../../../constants/modalFlags.json'
import ProviderShareModify from '../../modalScreens/providerShare'
import * as Images from "../../../utilities/images.js";
import { useTransactionSelector } from '../../../redux/selector/transaction.js'

export default function Transactions() {
    const dispatch = useDispatch()
    const transactionSelector = useTransactionSelector()
    const [pageLimit] = useState(10)
    const [loading, setLoading] = useState(true)
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [transactions, setTransactions] = useState([])
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setKey(Math.random());
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
    };

    const getAmountByPercentage = (amount, percent) => {
        let calculated = amount * (percent / 100)
        return calculated.toFixed(2)
    }

    const dateFormat = (date) => {
        let nowDate = new Date(date)
        let options = {
            timeZone: 'UTC',
            year: "numeric",
            month: "long",
            day: "numeric",
            hour12: true,
            hour: "2-digit",
            minute: "2-digit"
        }
        let formatedDate = nowDate.toLocaleString('en-GB', options)
        let dateChunks = formatedDate.split(" ")
        dateChunks.push(dateChunks.pop().toUpperCase())
        return dateChunks.join(" ")
    }
    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
    }
    const getTxnList = (page, limit) => {
        let params = {
            page: page ? page : currentPage,
            limit: limit ? limit : pageLimit,
        }
        dispatch(getAllTransactions({
            ...params,
            cb(res) {
                if (res.status === 200) {
                    setTransactions((prev) => {
                        setLoading(false)
                        setPageCount(res.data.data.total_pages || 0)
                        return res.data.data.payments
                    })
                } else if (res.notFound) {
                    setLoading(false)
                    setTransactions([])
                }
            }
        }))
    }
    const getProviderShare = () => {
        let params = {
            types: masterDataTypes.providerShare
        }
        dispatch(getTransactionShare({
            ...params,
            cb(res) { }
        }))
    }
    useEffect(() => {
        getTxnList()
        getProviderShare()
    }, [currentPage])

    function formatString(str) {
        let words = str.split('_');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    }
    return (
        <div className="content-wrapper adminUsers">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <div className='d-flex justify-content-between'>

                                <h1 className="m-0 headingMain">Transactions</h1>
                                <div className='providerShare'>
                                    <p className='m-0'>
                                        <span className='fw-bold'>Provider Share(%) :</span>
                                        <span>{`  ${transactionSelector?.shareData?.value}%` || ""}</span>
                                    </p>
                                    <img
                                        src={Images.EditIcon}
                                        className=""
                                        onClick={() => {
                                            setModalDetail({
                                                show: true,
                                                flag: modalFlags.providerShare,
                                            });
                                            setKey(Math.random());
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content commonBox_  userlistbox">
                <div className="container-fluid">
                    <table className="usertable ">
                        <thead>
                            <tr className="tableborder">
                                <th>User Name</th>
                                <th>Booking ID</th>
                                <th>Total Amount</th>
                                <th>Goes To Provider</th>
                                <th>Goes To Admin</th>
                                <th>Date & Time</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ?
                                <tr>
                                    <td colSpan={9} className='py-5'><SpinnerGrow /></td>
                                </tr> : transactions && transactions.length > 0 ? transactions.map((row, index) => {
                                    const providerShare = row.share ? row.share : transactionSelector?.shareData?.value || 60
                                    const adminShare = 100 - providerShare
                                    return (
                                        <tr key={`transaction_${index}`} className='txn_row'>
                                            <td>{row?.user?.userInfo?.name}</td>
                                            <td>#{row.jobId}</td>
                                            <td>${row.amount}</td>
                                            <td>${row.paymentType == "transfer" ? row.amount : getAmountByPercentage(row.amount, providerShare)}</td>
                                            <td>{row.paymentType == "transfer" ? "--" : `$${getAmountByPercentage(row.amount, adminShare)}`}</td>
                                            <td>{dateFormat(row.createdAt)}</td>
                                            <td>
                                                <div className={`txn_status_btn_${paymentStatusClasses[row.status]}`}>
                                                    <span>{paymentStatusNames[row.status]}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan={9} className='py-5'>
                                        <p className='text-center'>
                                            No transactions to show
                                        </p>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                    <div className="col-12 ">
                        <div className="customPagination mt-4">
                            <div className="">
                                {transactions.length > 0 ? (
                                    <ReactPaginate
                                        className="pagination"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={2}
                                        pageCount={pageCount}
                                        renderOnZeroPageCount={null}
                                        nextLabel={
                                            <span>
                                                Next <span className="double-arrow"></span>
                                            </span>
                                        }
                                        previousLabel={
                                            <span>
                                                <span className="double-arrow h5"></span> Prev
                                            </span>
                                        }
                                    />
                                ) : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop={true}
                showCloseBtn={true}
                isRightSideModal={true}
                bigModal={false}
                mediumWidth={false}
                keyboard={true}
                onCloseModal={() => handleOnCloseModal()}
                child={
                    modalDetail.flag === modalFlags.providerShare ? (
                        <ProviderShareModify
                            handleClose={handleOnCloseModal}
                            refreshShare={getProviderShare}
                        />
                    ) : ("")
                }
                header={
                    modalDetail.flag === modalFlags.providerShare ? (
                        <>
                            <h2 className="modal_Heading">Edit Provider Share</h2>
                        </>
                    ) : (
                        ""
                    )
                }
            />
        </div>
    )
}
