import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import routesConstants from "../../../constants/routes.json";
import modalFlags from "../../../constants/modalFlags.json";
import { encodeData } from "../../../utilities/helper";
import Swal from "sweetalert2";
import {
  deleteCategory,
  deleteService,
  getAllServices,
} from "../../../redux/slices/services";
import { useServiceSelector } from "../../../redux/selector/service";
import { Dropdown } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import SpinnerGrow from "../../../common/SpinnerGrow";
import CustomModal from "../../Modal/CustomModal";
import AddCategory from "./modalScreens/addCategory";
import AddService from "./modalScreens/addService";
import CustomToggle from "./CustomToggle";
import { toast } from "react-toastify";

export default function ManageSubServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const serviceSelector = useServiceSelector();
  const isLoading = serviceSelector.loading;
  const serviceList = serviceSelector?.serviceList || [];
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setKey(Math.random());
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };
  const handleDeleteService = (serviceId) => {
    Swal.fire({
      title: "Do you want to delete this service ?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "green-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          serviceId: serviceId,
        };
        dispatch(
          deleteService({
            ...params,
            cb(res) {
              if (res.status === 200) {
                toast.success("Deleted Successfully")
                getServiceByCategory();
              }
            },
          })
        );
      }
    });

  };

  const showModalByFlag = (flag, id, serviceName, serviceImage) => {
    setModalDetail({
      show: true,
      flag: flag,
      id: id,
      serviceName: serviceName,
      serviceImage: serviceImage,
    });
    setKey(Math.random());
  };

  const getServiceByCategory = () => {
    let params = {
      showAll: true
    };
    dispatch(
      getAllServices({
        ...params,
        cb(res) {
        },
      })
    );
  };

  const handleDeleteCategory = (categoryId) => {
    Swal.fire({
      title: "Do you want to delete this category ?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: {
        confirmButton: "green-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          categoryId: categoryId,
        };
        dispatch(
          deleteCategory({
            ...params,
            cb(res) {
              if (res.status === 200) {
                getServiceByCategory();
              }
            },
          })
        );
      }
    });
  };

  useEffect(() => {
    getServiceByCategory();
  }, []);

  return (
    <div className="content-wrapper adminUsers">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 headingMain">Services</h1>
            </div>
            <div className="col-sm-6 text-end">
              <button
                className="editcontentBtn grey"
                onClick={() => {
                  showModalByFlag(modalFlags.addCategory);
                }}
              >
                Add Category
              </button>
              <button
                className="editcontentBtn"
                onClick={() => {
                  showModalByFlag(modalFlags.addService);
                }}
              >
                Add Service
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="cotainer px-4">
        <Accordion alwaysOpen  >
          {isLoading ? (
            <div className="service_not_found">
              <SpinnerGrow />
            </div>
          ) : serviceList && serviceList.length > 0 ? (
            serviceList.map((service, service_index) => (
              <Accordion.Item
                eventKey={`service_${service_index}`}
                // eventKey={`openAll`}
                className="editService_AccordtionItem"
              >
                <Accordion.Header className="position-relative">
                  <h5>{service.name}</h5>
                  <Dropdown className="ml-100 custom-dropdown image-pos me-3">
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id={`dropdown-button-drop-${service_index}`}
                    />

                    <Dropdown.Menu className="custom-dropdown-menu custom-dropdown-menu-right">
                      <Dropdown.Item
                        onClick={() => {
                          showModalByFlag(
                            modalFlags.addServiceDropDown,
                            service._id
                          );
                        }}
                      >
                        Create Service
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.stopPropagation();
                          showModalByFlag(
                            modalFlags.editCategoryDropDown,
                            service._id,
                            service.name,
                            service.image
                          );
                        }}
                      >
                        Edit Category
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          handleDeleteCategory(service._id);
                        }}
                      >
                        Delete Category
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Accordion.Header>
                <Accordion.Body>
                  {service?.subServices && service?.subServices?.length > 0 ? (
                    service?.subServices.map((sub, index) => (
                      <div className="subservice_item">
                        <div>
                          <img
                            className="subservice_image"
                            src={sub?.image ? sub?.image : Images?.GreenGallery}
                            onError={(e) => { e.target.onerror = null; e.target.src = Images?.GreenGallery; }}
                            alt="image"
                          />
                          <span>{sub?.name}</span>
                        </div>
                        <div className="dropdown filterDropdown">
                          <span className="fw-bold">
                            {sub.isPending && 'Pending Info'}
                          </span>
                          <button
                            className="modalbtn ms-3 barbtn"
                            onClick={() => {
                              navigate(
                                `${routesConstants.subServices.edit
                                }${encodeData(sub?._id)}`
                              );
                            }}
                          >
                            {" "}
                            <i className="fas fa-edit"> </i>
                          </button>
                          <button
                            className="modalbtn ms-3 barbtn"
                            onClick={() => {
                              handleDeleteService(sub?._id);
                            }}
                          >
                            {" "}
                            <i className="fas fa-trash"> </i>
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center">
                      No Service Added
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))
          ) : (
            <div className="service_not_found">No Service Found</div>
          )}
        </Accordion>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop={true}
        showCloseBtn={false}
        isRightSideModal={true}
        bigModal={true}
        mediumWidth={false}
        size={'lg'}
        keyboard={true}
        onCloseModal={() => handleOnCloseModal()}
        ids={
          modalDetail.flag === modalFlags.addCategory
            ? modalFlags.addCategory
            : modalDetail.flag === modalFlags.addService
              ? modalFlags.addService
              : ""
        }
        child={
          modalDetail.flag === modalFlags.addCategory ? (
            <AddCategory
              handleClose={handleOnCloseModal}
              getServiceByCategory={getServiceByCategory}
            />
          ) : modalDetail.flag === modalFlags.addService ? (
            <AddService
              getServiceByCategory={getServiceByCategory}
              handleClose={handleOnCloseModal}
              addService={true}
            />
          ) : modalDetail.flag === modalFlags.addServiceDropDown ? (
            <AddService
              isDrop={true}
              getServiceByCategory={getServiceByCategory}
              categoryId={modalDetail.id}
              handleClose={handleOnCloseModal}
            />
          ) : modalDetail.flag === modalFlags.editCategoryDropDown ? (
            <AddCategory
              handleClose={handleOnCloseModal}
              serviceId={modalDetail.id}
              serviceName={modalDetail.serviceName}
              serviceImage={modalDetail.serviceImage}
              isEdit={true}
              getServiceByCategory={getServiceByCategory}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === modalFlags.addCategory ? (
            <>
              <h2 className="modal_Heading">Add Category </h2>
            </>
          ) : modalDetail.flag === modalFlags.addService ? (
            <>
              <h2 className="modal_Heading">Add Service</h2>
            </>
          ) : modalDetail.flag === "editCategoryDropDown" ? (
            <>
              <h2 className="modal_Heading">Update Category</h2>
            </>
          ) : modalDetail.flag === modalFlags.addServiceDropDown ? (
            <>
              <h2 className="modal_Heading"> Create Service</h2>
            </>
          ) : (
            ""
          )
        }
      />
    </div>
  );
}
