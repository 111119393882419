import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
  const isAuthenticated = Boolean(localStorage.getItem("adminAuthToken"));
  useEffect(() => {
    document.title = "Amigos"
  }, [])
  if (!isAuthenticated) {
    return children;
  } else if (isAuthenticated) {
    return <Navigate to="/" />;
  }
};

export default PublicRoute;
